import globalVariable from "../data/globalVariable";
import CustomOutlineButton from "./CustomOutlineButton";

interface HeaderSectionPageProps {
    title: string,
    image: string,
    text: string,
    isHasButton: boolean,
    buttonName: string,
    linkButton: string,
}

export default function HeaderSectionPage({ title, image, text,  isHasButton, buttonName, linkButton }: HeaderSectionPageProps) {
    return (
        <section className="headerHome">
            <img className="backgoroundHomeHeader" src={image} alt="headerPage" style={{ height: '550px' }} />
            <div className="contentHomeHeaderBox">
                <div className="headerTextSection">
                    <h1>{title}</h1>
                    <br />
                    <div className="miniBox"></div>
                    <br />
                    <p>{text}</p>
                </div>
                <br /><br />
                {
                    isHasButton ? <CustomOutlineButton link={globalVariable.mailTo} title="GET IN TOUCH HERE !" />
                        : <></>
                }
            </div>
        </section>
    )
}
