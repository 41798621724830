import ReactCompareImage from 'react-compare-image';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import NoteCourse from '../../../components/course/NoteCourse';

export function PageTwoFirstModule() {
    const serialNode = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694671542/Module/Module%201%20Node/Serial_Node_iuuwoh.png";
    const beeforeImage = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694671543/Module/Module%201%20Node/Serial_Before_conbjh.jpg";
    const serialStill = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694671543/Module/Module%201%20Node/Serial_After_kaklvz.jpg";
    const parallelNode = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694675107/Module/Module%201%20Node/parallel_efkyua.png";
    const parallelStill = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694675099/Module/Module%201%20Node/parallel_still_buy2my.jpg";
    const layerNode = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694675106/Module/Module%201%20Node/layer_zruxlf.png";
    const layerStill = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694675099/Module/Module%201%20Node/layer_still_mbjwrg.jpg";
    const parallelStackNode = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694678580/Module/Module%201%20Node/Parallel_Stack_Node_oxuqyk.png";
    const parallelImage = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694678581/Module/Module%201%20Node/Parallel_Stack_datyld.jpg";
    const layerStackNode = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694678580/Module/Module%201%20Node/Layer_Stack_node_byilue.png";
    const layerImage = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694678580/Module/Module%201%20Node/Layer_Stack_jfr3fq.jpg";
    const outsideNode = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694675106/Module/Module%201%20Node/outside_bn46nh.png";
    const outsideStill = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694675098/Module/Module%201%20Node/outside_still_ip2deu.jpg";

    return (
        <>
            <div className="moduleWrap">

                {/* Serial Node */}
                {/* =========================================================== */}
                <br />
                <TextSectionCourse noTitile={false} title='1. Serial Node' text='Serial node is basic node, you can put any adjustment in here such as exposure, saturation, contrast, secondary etc. look at image down below I add some adjustment and you can se before and after.' />
                <NoteCourse note='(We will talk how to do it in the next module, I just want to make you imagine how the serial node work).' />
                <img className='collapseImage' src={serialNode} alt="" />
                <ReactCompareImage leftImageLabel='Before' leftImage={beeforeImage} rightImageLabel='After' rightImage={serialStill} sliderLineWidth={2} />
                <br /><br />

                {/* Serial Node */}
                {/* =========================================================== */}

                {/* Parallel Mixer Node */}
                {/* =========================================================== */}

                <TextSectionCourse noTitile={false} title='2. Parallel Node' text='Parallel node basically you just stack the adjustment to your image, this concept will take from one input source and share it to serial node to make a stack and then put all output from serial node to parallel mixer node, every stack you make will be affected to another stack you create, for expample below I use stack and connect to parallel mixer node, on top I use for lift gamma gain to add some color, and the bottom I use hue vs hue to push the skin color into orange.' />

                <img className='collapseImage' src={parallelNode} alt="" />
                <ReactCompareImage leftImageLabel='Serial' leftImage={serialStill} rightImageLabel='Parallel' rightImage={parallelStill} sliderLineWidth={2} />
                <br /><br />

                {/* Parallel Mixer Node */}
                {/* =========================================================== */}

                {/* Layer Mixer Node */}
                {/* =========================================================== */}

                <TextSectionCourse noTitile={false} title="3. Layer Mixer Node" text="If you take a look on image below,the layer node and parallel node has a same concept, yess the stacking concept, but if parallal will be affected another color when you stack it, that's not happend on layer mixer node, layer mixer just make a stack without mix the color you create, so many people using it to seperate the background and skin, in this example I will do the same thing, I will add warm color on her skin and add cool color at the background." />
                <NoteCourse note='From the node image you can see, the bottom node is the top of the pile' />

                <img className='collapseImage' src={layerNode} alt="" />
                <ReactCompareImage leftImageLabel='Parallel' leftImage={parallelStill} rightImageLabel='Layer' rightImage={layerStill} sliderLineWidth={2} />
                <br /><br />

                {/* Layer Mixer Node */}
                {/* =========================================================== */}

                {/* =========================================================== */}
                {/* Parallel vs Layer Mixer Node */}

                <TextSectionCourse noTitile={false} title="Parallel VS Layer Mixer Node" text="I know maybe it will hard to understand for the first time, now I will try to make it clear using the same node tree and I just change the Parallel and Layer mixer only, Let's take a look." />
                <NoteCourse note='Key Word : Parallel Mixer make a stack and affected each stack when you add adjustment.' />
                <NoteCourse note='Key Word : Layer Mixer make a stack and not affected each stack when you add adjustment.' />
                <br />
                <div className="flexImageTitle">
                    <div className="imageWithTitleBox">
                        <h3>Parallel Mixer Node</h3>
                        <img className='collapseImageTitle' src={parallelStackNode} alt="" />
                    </div>
                    <div className="imageWithTitleBox">
                        <h3>Parallel Mixer Image</h3>
                        <img className='collapseImageTitle' src={parallelImage} alt="" />
                    </div>
                </div>
                <br />
                <div className="flexImageTitle">
                    <div className="imageWithTitleBox">
                        <h3>Layer Mixer Node</h3>
                        <img className='collapseImageTitle' src={layerStackNode} alt="" />
                    </div>
                    <div className="imageWithTitleBox">
                        <h3>Layer Mixer Image</h3>
                        <img className='collapseImageTitle' src={layerImage} alt="" />
                    </div>
                </div>
                <br /><br />
                {/* Parallel vs Layer Mixer Node */}
                {/* =========================================================== */}


                {/* Outside Node */}
                {/* =========================================================== */}

                <TextSectionCourse noTitile={false} title='4. Outside Node' text='And the last this is Outside Node, the function of this node to create adjustment on the outside masking area, in this case I try to add mask on her face area and I make it brighter, and then I use outside node to make outseide face area to look darker to make focus on her face.' />

                <img className='collapseImage' src={outsideNode} alt="" />
                <ReactCompareImage leftImageLabel='Layer' leftImage={layerStill} rightImageLabel='Outside' rightImage={outsideStill} sliderLineWidth={2} />


                {/* Outside Node */}
                {/* =========================================================== */}
                <br />
            </div >

        </>
    )
}


