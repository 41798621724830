
interface CustomOutlineButtonProps {
    title: string,
    link: string
}

export default function CustomOutlineButton({ title, link }: CustomOutlineButtonProps) {
    return (
        <>
            <a className="customOutlineButton" href={link} target="_blank" rel="noreferrer">{title}</a>
        </>
    )
}
