import CardBlog from "../components/CardBlog";
import { CustomFooter } from "../components/CustomFooter";
import courseData from '../data/courseData.json';

export function CoursesPage() {
    const userStatus = localStorage.getItem('status');
    const role = localStorage.getItem('role');
    return (
        <div className="backgroundBlog">
            <div className="boxListBlog">
                {
                    courseData.map((course, index) => <CardBlog key={index} image={course.image} title={course.title} desc={course.description} date={course.createdDate} linkTo={(userStatus === '' || userStatus === null) && index > 2 ? '/login' : role === "friend" && index > 4? '/course' : course.linkTo} status={(userStatus === '' || userStatus === null) && index > 2 ? false : role === 'friend' ? index < 5 : true} />)
                }

            </div>

            <CustomFooter />
        </div>
    )
}

