import './courseStyle.css'

interface TextSectionCourseProps {
    title: string,
    text: string,
    noTitile: boolean;
}

export default function TextSectionCourse({ noTitile, title, text }: TextSectionCourseProps) {
    return (
        <div className="containerContentCourse">
            <div className="contentCourseText">
                {
                    noTitile ? <div className='courseContentSpace'></div> :
                        <h1>{title}</h1>
                }
                <p>{text}</p>
            </div>
        </div>
    )
}

