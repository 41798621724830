import './courseStyle.css'

interface NoteCourseProps {
    note: string,
}

export default function NoteCourse({ note }: NoteCourseProps) {
    return (
        <div className="containerContentCourse">
            <div className="contentCourseText">
                <p className='noteMark'>{note}</p>
            </div>
        </div>
    )
}

