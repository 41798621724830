import globalVariable from '../../data/globalVariable'
import './courseStyle.css'

interface HeaderCourseProps {
    title: string,
    subtitle: string,
    imageCover: string,
}

export default function HeaderCourse({ title, subtitle, imageCover }: HeaderCourseProps) {
    return (
        <>
            <div className="moduleTitleHeader">
                <div className="titleDirection">
                    <div className="boxImageTitle">
                        <h1>{title}</h1>
                        <h2 style={{ marginTop: '3px' }}>{subtitle}</h2>
                    </div>
                    <div className="authorBox">
                        <img className='authorImage' src={globalVariable.authorCourseImage} alt="" />
                        <p>Aqib Alvaaiziin</p>
                    </div>
                </div>
                <img className='imageHeaderModule' src={imageCover} alt="" />
            </div>
        </>
    )
}

