import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageThreeFifthModule() {

    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title="Do you need organizing your workflow ?" text="For me personally it depends on the situation, if I do some project of course I will organize my workflow, but if just for crate short timeline content I didn't need it, for content I mostly use shared node for noise reduction purpose, so just pick the method for the right job, this organizing features is optional but if you understand with what you doing here it will safe a lot of time." />
                <br />
                <TextSectionCourse noTitile={false} title="When you need to organized it ?" text="I personally when get some project I will add some CST color space transform in timeline level so I know how the rec 709 look from my project, and then I watch the entire video to find out the scene throughout the video, from there I will have a plan to grouping my clip by scene, this step so importatnt for me because it will make my workflow better and less confusing, I said confusing because when you work on long timeline and you not organized your workflow it would be hard to find the error we make, and we play with the time for that." />

                <br />
            </div>

        </>
    )
}


