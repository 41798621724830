import './courseStyle.css'

interface ListDataCourseProps {
    listData: string[];
}

export default function ListDataCourse({ listData }: ListDataCourseProps) {
    return (
        <div className="containerContentCourse">
            <ul>
                {listData.map((data) =>
                    <li><p className='textListSize'>{data}</p></li>
                )}
            </ul>
        </div>
    )
}

