import ReactCompareImage from 'react-compare-image';
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';
import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ListDataCourse from '../../../components/course/ListDataCourse';
import ImageContentCourse from '../../../components/course/ImageContentCourse';

export function PageTwoFourthModule() {
    const node = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%204%2FNode%20Color%20Manage.png?alt=media&token=46abb730-0b59-484e-9214-9aecdce0ba57";
    const inputOutput = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%204%2FIDT%20ODT.png?alt=media&token=854944fb-fc19-40db-8349-a03b3d01753a";
    const projectSettingColorManage = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%204%2Fproject%20setting%20color%20manage.png?alt=media&token=3b066c7b-898b-42b5-a3d3-21f65e9ec2e9";



    const nodebase = [
        {
            "title": "Node Base",
            "value": "Color Management Node Base workflow basically we create our pipeline in a node tree, so we will create in the first node for input camera and scene color space we want to use and then create one node in the last for output scene and display color space, now you can create another between two node and your in color management right now.",
            "image": ""
        },
    ]

    const projectSetting = [
        {
            "title": "Project Setting",
            "value": "This method is different with Node Base Color Management, with this method we can change color management for a whole project, this step will save your time a lot than using Node Base Color Management, To set color management follow the step below : ",
            "image": ""
        },
    ]
    const dataWorkflow = [
        "Go to project Setting",
        "Choose Color Management",
        "Change Color Science to Davinci YRGB Color Manage",
        "Uncheck Automatic color management",
        "Set Color Processing Mode to 'Custom'",
        "For input color space you can choose your camera profile if you work on one camera, but if your project use more than camera just set 'same as timeline'",
        "Set output color space you want to deliver in this case Rec. 709",
        "And then just click save"

    ];
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={true} title="" text="In this section I will explain 2 model of color managemenet, first using node base and second using project setting, what the different between them let's take a look." />

                <NoteCourse note="I will explain in Davinci Wide Gamut scenario, if you want to using Aces it will be the same process so don't be counfused okey!!!" />
                <p style={{ margin: '0px 4%' }}>😁😁😁😁</p>
                <br />

                <ListDataCourseTitle listData={nodebase} />
                <ReactCompareImage leftImageLabel='Node' leftImage={node} rightImageLabel='Input/Output' rightImage={inputOutput} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title="" text="with set the node like this now you're in color management pipeline, now all adjustment you create in working space (green box) will be affected by tone curve scene color space in this case Davinci Wide Gamut Colorspace." />
                <br />
                <ListDataCourseTitle listData={projectSetting} />
                <div style={{ margin: '-20px 3%' }}>
                    <ListDataCourse listData={dataWorkflow} />
                </div>
                <br />
                <ImageContentCourse image={projectSettingColorManage} title='colorspace' />
                <TextSectionCourse noTitile={true} title="" text="if you set input color 'space as timeline' you need to tell resolve what camera you use in your footage, just right click on thumbnail clip and choose input color space and then choose your camera profile, now your'e footage already in color management workflow and your good to go." />
                <br /><br />

            </div>

        </>
    )
}


