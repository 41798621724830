import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';

export function PageThreeThirdModule() {
    const listBeforeGrade = [
        {
            "title": "Good Reference",
            "value": "To create a really good look you need to find references that match with your material, you need to find the same environment, same feeling, and same massage you want to tell to your audince, never use the reference just look cinematic but not fit at all with your material environment and not support to tell your messages",
            "image": ""
        },
        {
            "title": "Message",
            "value": "In color grading you need to understand what emotion you want to describe to your audience, you need to understand which part from your environtment in a video can support the story you want to emphasize, so now you know the color grading process is not only to create unique or cool look, but you can do more than that, the key part of this process tell the story with the color you make, the best way to upgrade your skill for this process is watch any references like movie, music video, commercial video ECT.",
            "image": ""
        },
        {
            "title": "Do Not Exaggerate",
            "value": "It means just keep your workflow simple, look at my node tree down below, to tell the story from previous page I just use simple node, that just basic correction, add split tone, and control the color palette, with simple workflow it will save your'e time a lot.",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FNode%20Color%20Grade%20(1).png?alt=media&token=5ba099cc-dc4e-420a-b615-c3231dfe0c6f"
        }
    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title='Succes Key For Color Grading Process' text="  " />
                <ListDataCourseTitle listData={listBeforeGrade} />
                <br />
            </div>

        </>
    )
}


