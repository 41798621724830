import { useEffect, useState } from "react";
import ReactCompareImage from 'react-compare-image';
import useWindowDimensions from '../components/WindowDimensions';

interface DetailProjectStillsProps {
    stills: { imageId: number, before: string, after: string, pallete: string }[],
    title: string,
    subtitle: string
}

export default function DetailProjectStills({ stills, title, subtitle }: DetailProjectStillsProps) {
    const [showModal, setShowModal] = useState(false);
    const [showImageId, setImageId] = useState(0);
    const [showImageBefore, setImageBefore] = useState('');
    const [showImageAfter, setImageAfter] = useState('');
    const [showPallete, setSetPallete] = useState('');
    const { width, height } = useWindowDimensions();

    const handleNextImage = () => {
        if (showImageId <= 10) {
            let nextImage = stills.filter((datas => datas.imageId === showImageId + 1));
            setImageId(showImageId + 1);
            setImageBefore(nextImage[0].before);
            setImageAfter(nextImage[0].after);
            setSetPallete(nextImage[0].pallete);
        }
    }

    const handleBackImage = () => {
        if (showImageId >= 1) {
            let backImage = stills.filter((datas => datas.imageId === showImageId - 1));
            setImageId(showImageId - 1);
            setImageBefore(backImage[0].before);
            setImageAfter(backImage[0].after);
            setSetPallete(backImage[0].pallete);
        }
    }

    useEffect(() => {
        if (showModal) {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
            document.getElementById('custNav')!.style.display = 'none';
        } else {
            document.getElementById('custNav')!.style.display = 'block';
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
        window.onpopstate = e => {
            document.getElementById('custNav')!.style.display = 'block';
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    })

    const palleteBuild = () => <>
        <img src={showPallete} alt={"Pallete"} width={'100%'} style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        }} />
        <br />
    </>

    const componentBuild = <>
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none" style={{ backgroundColor: '#0313164a' }}>
            <div className="flex items-center justify-between border-b border-solid border-slate-200 rounded-t headerTitlePadding">
                <div className="headerTitleModal">
                    <h3 className="text-3xl font-semibold">
                        {title}
                    </h3>
                    <p>{subtitle}</p>
                </div>

                <button
                    className="border-0 text-black float-right text-3xl leading-none font-semibold" onClick={() => { setShowModal(false) }}>
                    <span className="closeButton">X</span>
                </button>
            </div>
            <br />
            <div className="imageSlider" style={{
                width: '100%',
                border: "0.5px solid white", borderRadius: '12', display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
            }} >
                <ReactCompareImage leftImage={showImageBefore} rightImage={showImageAfter} sliderLineWidth={2} handleSize={30}
                />
                <div className="backImageButton" onClick={() => handleBackImage()}></div>
                <div className="nextImageButton" onClick={() => handleNextImage()}></div>
            </div>
            {palleteBuild()}
        </div>
    </>

    return (
        <>
            <div className="boxProjectImageGallery">
                {stills.map((still) => <div className="imageGalleryProject">
                    <img onClick={() => { setShowModal(true); setImageId(still.imageId); setImageBefore(still.before); setImageAfter(still.after); setSetPallete(still.pallete) }} className="stillImageContent" src={still.after} alt={still.after} key={still.after} />
                    {showModal ? (
                        <>
                            <div
                                className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none paddingModal"
                            >
                                {width! < 305 ? <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '90%', padding: '0%' }}>
                                    {componentBuild}
                                </div>
                                    : width! >= 306 && width! < 767 ? height! >= 280 && height! < 400 ? <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '50%', padding: '0% 23%' }}>
                                        {componentBuild}
                                    </div> : <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '90%' }}>
                                        {componentBuild}
                                    </div> : width! >= 768 && width! < 992 ? <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '40%', }}>
                                        {componentBuild}
                                    </div> : width! >= 993 && width! < 1198 ? height! >= 400 && height! < 620 ? <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '50%', padding: '0% 16%' }}>
                                        {componentBuild}
                                    </div> : <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '40%', }}>
                                        {componentBuild}
                                    </div> : width! >= 1199 && width! < 1399 ? height! >= 620 && height! < 780 ? <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '50%', padding: '0% 15%' }}>
                                        {componentBuild}
                                    </div> : <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '40%', }}>
                                        {componentBuild}
                                    </div> : width! >= 1400 ? <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '50%', padding: '0% 15%' }}>
                                        {componentBuild}
                                    </div> : <div className="relative my-6" id="modalSizeSlider" style={{ minWidth: '45%', padding: '0% 15%' }}>
                                        {componentBuild}
                                    </div>}
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                </div>
                )}
            </div>

        </>
    )
}




