import { Tab } from '@headlessui/react'
import { CustomFooter } from '../../../components/CustomFooter';
import HeaderCourse from '../../../components/course/HeaderCourse';
import dataCourse from '../../../data/courseData.json'
import '../course.css'
import { PageOneSeventhModule } from './PageOneSeventhModule';
import { PageTwoSeventhModule } from './PageTwoSeventhModule';


function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export function SeventhModule() {
    const course = dataCourse[6];
    const categories = [
        {
            "id": "1",
            "title": "Definition",
            "content": <PageOneSeventhModule />
        },
        {
            "id": "2",
            "title": "Tutorial",
            "content": <PageTwoSeventhModule />
        },
        // {
        //     "id": "3",
        //     "title": "How To Save as LUT",
        //     "content": <PageThreeSeventhModule />
        // },
    ];

    const status = localStorage.getItem('status');
    const role = localStorage.getItem('role');
    if (status === '' || status === null) {
        window.location.reload();
        window.location.href = '/login';
    } else {
        if (role === 'friend') {
            window.location.reload();
            window.location.href = '/course';
        }
    }


    // fcba03
    return (
        <>

            <div className="moduleWrap">
                <br />
                <HeaderCourse title={course.title} subtitle={course.createdDate} imageCover={course.image} />
                <br />
                <div style={{ margin: '0px 10%' }}>

                    <Tab.Group>
                        <div className="wrapTabs">
                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                                {categories.map((data) =>
                                    <Tab
                                        key={data.id}
                                        className={({ selected }) =>
                                            classNames(
                                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                                selected
                                                    ? 'bg-white text-black shadow font-bold'
                                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                            )
                                        }
                                    >
                                        {data.title}
                                    </Tab>
                                )}
                            </Tab.List>
                        </div>
                        <Tab.Panels className="mt-2">
                            {categories.map((data, id) =>
                                <Tab.Panel key={id}>
                                    <div>{data.content}</div>
                                </Tab.Panel>
                            )}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>


            <CustomFooter />
        </>
    )
}




