import ImageContentCourse from '../../../components/course/ImageContentCourse';
import ListDataCourse from '../../../components/course/ListDataCourse';
import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageThreeFirstModule() {
    const workflowNode = 'https://res.cloudinary.com/djhmz6ago/image/upload/v1694683668/Module/Module%201%20Node/Workflow_vvnmvc.png';
    const dataWorkflow = [
        "Node 1 and 18 I use for input camera and output display using Color Space Transform OFX",
        "Node 2 I use for correction using my custom Linear Correction DCTL",
        "Node 3 for base look, I use my custom Split Tone DCTL",
        "Node 4 I use my custom Crosstalk DCTL, to create seprate color on skin",
        "Node 5 for creative this, sometimes I use lift, gamma, gain if needed",
        "Node 7 - 8 I use Tetra DCTL one of the most popular and powerfull dctl you can find it on internet",
        "Node 9 - 14 I combine outside node to parallel mixer, just for my shortcut to do relighting",
    ];
    return (
        <>
            <div className="moduleWrap">
                {/* Which Node Will Fit For You ? */}
                <br />
                <TextSectionCourse noTitile={false} title='Which Node Will Fit For You ?' text="After know type of nodes maybe you will ask your self about which node you need for your task, actually it depens on look or color you want to achive, let's take a look on image below I will explain my current node tree workflow for color grading content." />

                <ImageContentCourse title='node image' image={workflowNode} />
                {/* Which Node Will Fit For You ? */}

                <ListDataCourse listData={dataWorkflow} />
                <br />
                <TextSectionCourse noTitile={true} title='' text="That's my current workflow for crate content guys, it's just that simple like that, if you want to know more you can join PCC(Private Color Class) by me." />

                <NoteCourse note='If you join my class you will get the my Custom DCTL bundle' />
                <br />
            </div>

        </>
    )
}


