import BreakPage from "../components/BreakPage";
import { CustomFooter } from "../components/CustomFooter";
import CustomOutlineButton from "../components/CustomOutlineButton";
import HeaderSectionPage from "../components/HeaderSectionPage";
import VerticalImage from "../components/VerticalImage";
import globalVariable from "../data/globalVariable";
import dataImage from "../data/galleryImage.json";

export function AboutPage() {
    return <>
        <HeaderSectionPage linkButton="" isHasButton={false} buttonName="For colaborration here !" title="About Me" image="https://res.cloudinary.com/djhmz6ago/image/upload/v1675787445/colorist/bapak/Untitled_1.143.1_q9sp9l.jpg" text="Digital Intermediate (Colorist)" />

        <div className="boxContentAbout">
            <div className="boxWhoMe">

                <div className="imageprofile">
                    <img src="https://res.cloudinary.com/djhmz6ago/image/upload/v1676180680/colorist/profile/DSCF2457_dvl7fh.jpg" alt="myphoto" loading="lazy" style={{
                        display: 'block', objectFit: 'cover', width: '100%', height: '100%',
                    }} />
                </div>
                <div className="boxDescriptionMe">
                    <h1>Who I am ?</h1>
                    <span>A little bit about me and Effective Colorista</span>
                    <br />
                    <div className="miniBox"></div>
                    <br />
                    <p>Hai, My name Aqib Alvaaiziin I am Digital Intermediate (Colorist) based in Indonesia, I have a background as a software developer and now I'm exploring the Digital Intermediate field as my main job. And then I create Effective Colorista to give services for color correction and color grading for my client video.</p><br />
                    <p>If you want to know more about me  you can follow my social media, I active upload some content there, especially on Instagram.</p>
                    <br />
                    <p className="titleSosmed" style={{ marginBottom: '8px' }}>Social Media :</p>
                    <div className="boxSocialMedia">
                        <a href={globalVariable.instagramLink} target="_blank" rel="noreferrer">
                            <svg className="h-8 w-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={globalVariable.linkedInLink} target="_blank" rel="noreferrer">
                            <svg className="h-8 w-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />  <rect x="2" y="9" width="4" height="12" />  <circle cx="4" cy="4" r="2" /></svg>
                        </a>
                    </div>
                    <br /><br />
                </div>
            </div>
            <div className="boxDoingAbout">
                <div className="boxDescriptionMe">
                    <h1>Who I do ?</h1>
                    <span>The services I provide</span>
                    <br />
                    <div className="miniBox"></div>
                    <br />
                    <p>I do services for color correction and color grading Documentary, TVC, Music Video and Short Film, and I give services to using specific color space you want, I hope we can build a good cooperation and we can realize your brilliant idea in color form.</p>
                    <br />
                    <p>I mostly work remotely for my client so if you interest to collab with me you can send me email here.</p>
                    <br /><br />
                    <div className="div">
                        <CustomOutlineButton link={globalVariable.mailTo} title="GET IN TOUCH HERE !" />
                    </div>
                    <br />
                </div>
                <div className="imageprofile">
                    <img src="https://res.cloudinary.com/djhmz6ago/image/upload/v1676180727/colorist/profile/DSCF2481_hncryn.jpg" alt="mystudio" loading="lazy" style={{
                        display: 'block', objectFit: 'cover', width: '100%', height: '100%',
                    }} />
                </div>
            </div>

        </div>
        <BreakPage title="My Latest Project" subtitle="some project I have ever done" link="/portfolio" isHasLink={true} />
        <p style={{ color: 'white', fontSize: 17, backgroundColor: '#05171c', textAlign: 'right', paddingRight: 25, fontWeight: 'bold', paddingBottom: 10 }}>
            Slide Right {"-->"}
        </p>

        <div className="imageVerticalContainer">
            {dataImage.map((item) =>
                <VerticalImage imageId={item.id} title={item.title} image={item.mainImage} subtitle={item.subtitle} />
            )}

        </div>
        <CustomFooter />
    </>
}