import ReactCompareImage from 'react-compare-image';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ImageContentCourse from '../../../components/course/ImageContentCourse';
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';

export function PageTwoSecondModule() {
    const node = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FNode.png?alt=media&token=7f37aeb4-f069-4379-b541-8ba18c01171a";
    const logImage = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FLog.jpg?alt=media&token=7c127c95-da51-4091-9042-3b05b1b7ad28";
    const rec = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FRec.jpg?alt=media&token=ce69a4ca-e0ac-4e95-8e7a-e15ae4f42fd2";
    const corrected = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2Fcorrected_1.10.5.jpg?alt=media&token=50626dbf-e12b-4322-a707-52152038c0f8";

    const correctionStep = [
        {
            "title": "Transform to Display Color",
            "value": "First of all before start color correction you need to transform from camera color to display color, in this case I transform from Blackmagic camera to Rec. 709, this is important proses before you start any correction so always know what camera profile color you use, in here I using Color Space Transform OFX, and then do any correction before the CST node, it will give you advantage to use all dynamic range of your camera.",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FContrast%20(3)%20(1).png?alt=media&token=58f73a0b-0999-419d-94f9-01a735a1fc9f"
        },
        {
            "title": "Exposure & Contrast",
            "value": "Before you start adjust your exposure and contrast I suggest you to set your setting first, go to project settings -> General Option -> Turn off 'Use S-Curve for contrast', this proses will make your contrast knob work linearly and not make s-curve, it will help you to keep data from the camera without put any curve adjustment because s-curve process is already done when you do transform in previous step.",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FContrast%20(1).png?alt=media&token=05355cda-c6d1-4e7d-b5cc-32578001102d"
        },
        {
            "title": "Saturation",
            "value": "Because this image look less saturated I add Saturation and a litle bit Color Boost, Saturation knob will increase all saturation on the image, and the Color Boost knop will help push the saturasion in the lowest saturation area in this case like his skin.",
            "image": ""
        },
        {
            "title": "White Balance",
            "value": "I know Resolve provide the Temp & Tint knob for white balance, but that knob not work better than you use the Offset wheel because when you use the Offset wheel every data from shadow to highlight will be adjusted which mean this is good becaus the camera white balance fatures work with same concept, if you use Temp & Tint this knob work a bit different because this knob not affected to black area.",
            "image": ""
        },
    ]
    return (
        <>

            <div className="moduleWrap">
                <br />
                <TextSectionCourse noTitile={false} title='' text="To start color correction I will give you node tree scenario in down below." />
                <ImageContentCourse image={node} title='node' />
                <ListDataCourseTitle listData={correctionStep} />
                <br />
                <ReactCompareImage leftImageLabel='Log' leftImage={logImage} rightImageLabel='Rec. 709' rightImage={rec} sliderLineWidth={2} />
                <br />
                <ReactCompareImage leftImageLabel='Rec. 709' leftImage={rec} rightImageLabel='Corrected' rightImage={corrected} sliderLineWidth={2} />
                <br />
            </div>

        </>
    )
}


