import { Link } from 'react-router-dom';

interface CardImageProps {
    imageId: string,
    image: string,
    title: string,
    subtitle: string,
}

export default function CardImage(this: any, { imageId, image, title, subtitle }: CardImageProps) {


    return (
        <div className="cardImageContainer">
            <Link to={`/detailProject?id=${imageId}`}>
                <img src={image} alt={title} height="20%" style={{
                    objectFit: 'cover', overflow: 'hidden',
                }} />
                <div className="boxNavigateDetail">
                    <div className="cardDescription">
                        <h1>{title}</h1>
                        <p>{subtitle}</p>
                    </div>
                    <span className='buttonNavigate'>See Detail</span>
                </div>
            </Link>
        </div>

    )
}
