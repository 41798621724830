import ImageContentCourse from '../../../components/course/ImageContentCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageOneFirstModule() {

    return (
        <>

            <div className="moduleWrap">
                <br />
                <TextSectionCourse noTitile={false} title='What Actually Node is ?' text='Davinci resolve using the node tree concept, nodes are the blocks to place your idea of color adjustment, which means you can do anything you want to adjust like exposure saturation, contrast, etc. to your image. each nodes has 2 source (on the left side) and 2 output (on right side).' />

                <ImageContentCourse title='node image' image='https://res.cloudinary.com/djhmz6ago/image/upload/v1694661968/Module/Module%201%20Node/Node_fharxl.png' />

                <TextSectionCourse noTitile={true} title='' text='Davinci Resolve has flow from the input RGB that will send the data to the first node and forward the data through the next node and then at the end it will be connected to the output RGB.' />

                <ImageContentCourse title='node image' image='https://res.cloudinary.com/djhmz6ago/image/upload/v1694661967/Module/Module%201%20Node/Node_Tree_vxd02h.png' />

                {/* what actually node tree is */}
                {/* =========================================================== */}


                {/* =========================================================== */}
                {/* Type Of Node Tree node tree is */}

                <TextSectionCourse noTitile={false} title='Type of Node Tree ?' text='Node have several types, there are serial node, parallel mixer node, layer mixer node, outside node, keyer mixer node, splitter node, and combiner node, but for now we will talk the most I used for my color grading process that is serial node, parallel mixer node, layer mixer node, outside node. Take a look on the picture below, serial node is a basic node to put any adjustment you make, parallel node (red mark on Image) has a concept to allows you combine several adjustments from the same source, layer node (blue mark on Image) allows you to stack multiple adjustments from the same source without affecting the stack you have already created, the last thing is the outside node (green mark on Image) this node works when you use any qualifying features such as power window or qualifier it will affect the outside area of that qualified area' />


                {/* Type Of Node Tree node tree is */}
                {/* ========================================================= */}

                <ImageContentCourse title='node image' image='https://res.cloudinary.com/djhmz6ago/image/upload/v1694661967/Module/Module%201%20Node/Type_Node_uqnxck.png' />
                <br />

                {/* ========================================================== */}
            </div>

        </>
    )
}


