import ReactCompareImage from 'react-compare-image';
import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';

export function PageOneSecondModule() {
    const logImage = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694766195/Module/Module%202%20Color%20Correction/1.3.1_1.3.1_qkagcp.jpg";
    const rec = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694766196/Module/Module%202%20Color%20Correction/1.3.3_1.3.3_czx7gr.jpg";
    const corrected = "https://res.cloudinary.com/djhmz6ago/image/upload/v1694766196/Module/Module%202%20Color%20Correction/1.3.2_1.3.2_ffgdrl.jpg";

    const listCorrection = [
        {
            "title": "Exposure & Contrast",
            "value": "Exposure refer to how much light on the image, it can be dark or bright depends on the correction you want to create, Cotrast is how big distance between the darkest area to brightest area, to know how much Exposure & Contrast on your image you can use Waveform Scope.",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FWaveform.png?alt=media&token=e4175ced-e783-481f-9289-4b1aedf389a9"
        },
        {
            "title": "White Balance",
            "value": "White Balance in color correction aim to remove unrealistic color cast on the image, camera has white balance setting too sometimes the camera guy not set the white balance properly, so the image will be look too greenish, warm or cold to fix this you can read parade scope to make sure your image balanced, parade scope will help you read RGB value from shadow to highlight so you can adjust your image for the black shloud be black and for white should be black, some people not balancing proper black and white, it depends on the look you want to create.",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FParade.png?alt=media&token=4d323d93-185c-423f-b922-f83c19f09b73"
        },
        {
            "title": "Saturation",
            "value": "Saturation refer to how colorfull your image, you can see it using Vectorscope.",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FVectorscope.png?alt=media&token=195f9f04-fae7-423b-af13-c6de70044db7"
        },
    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title='What the right definition about color correction?' text='Color Correction is process to fix the wrong or unwanted color in a footage to look like as natural as posibble.' />

                <NoteCourse note='What is that mean with wrong or unwanted color?' />

                <TextSectionCourse noTitile={true} title='' text="In a production errors often occur, errors such as overexposed, underexposed, wrong white balance settings, so this error will  be corrected in the post-production process, now let's take a look on image down below, this image is unbalance and overexposed, with Color Correction process you can make look better, in this image I try to convert from log image into Rec.709 and control exposure, contrast, white balance to make the image looks right." />
                <br />
                <ReactCompareImage leftImageLabel='Log' leftImage={logImage} rightImageLabel='Rec. 709' rightImage={rec} sliderLineWidth={2} />
                <br />
                <ReactCompareImage leftImageLabel='Rec. 709' leftImage={rec} rightImageLabel='Corrected' rightImage={corrected} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title='' text='You can see how important this process is, when you do this process well it will make it easier for you to move on to the next process like create look.' />

                <TextSectionCourse noTitile={false} title="Key Point of Color Correction" text='In color correction you need to pay attention on this point.' />
                {/* what actually node tree is */}
                {/* =========================================================== */}
                <ListDataCourseTitle listData={listCorrection} />
            </div>

        </>
    )
}


