import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageOneFifthModule() {
    const workflow = [
        {
            "title": "Shared Node",
            "value": "Shared Node has concept when you create some adjustment on node you can save it and share the adjustment into entire clip in timeline, I'll give you scenario you need noise reduction features and you create node for noise reduction and save it into shared node, now you can use that node for entire timeline and if you change the adjustment, it will be affected to all clips with that shared node.",
            "image": ""
        },
        {
            "title": "Grouping",
            "value": "This is advance technique to manage a scene, but it's not complicated at all, with this concept you can group clip by scene or lighting condition it depends on you, I will deep dive this concept in next page.",
            "image": ""
        }
    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={true} title="" text="In this module we will talk about efficiency when you color grade on some project, we will focus on how you manage the clip and create look to make it more consistant, to make your workflow more manageable Davinci Resolve provide some feature to do it, let's talk about it." />
                <br />

                <TextSectionCourse noTitile={false} title="What Are The Advantages of Organizing ?" text="Maybe you are thinking about the benefits of organizing workflow, I'll give you scenario when you work on 100 clip and that has 5 scene with different look and location, if you no manage your clip it will takes so much time when you get some revision from DP or director, it will ruin you're consetration when you not manage your workflow, so the solution you need to manage your'e clip in group each scene, it will safe a lot of time, just imagine if you work on long project with dozens of scenes, how hard to manage it." />
                <br />

                <TextSectionCourse noTitile={false} title="Type of Organizing" text="Now I will explain most scenario I use to manage my workflow" />
                <br />


                <ListDataCourseTitle listData={workflow} />



                <br />
            </div>

        </>
    )
}


