import './App.css';
import MainRoutes from './router/Routes';
// In your component:
function App() {



  return (
    <div className='app' style={{ width: '100%', overflowX: 'hidden' }}>
      <MainRoutes />
    </div>

  );
}

export default App;

