import { Outlet } from "react-router-dom";
import CustomNavbar from "../components/CustomNavbar";

const MainPage = () => {
    return <div>
        <CustomNavbar />
        <Outlet />
    </div>
}

export default MainPage;