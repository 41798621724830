import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import globalVariable from '../../../data/globalVariable';
import '../course.css'

export function PageOneSixthModule() {

    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title="What is DCTL ?" text="DCTL is short for DaVinci Color Transform Language. It's a scripting language used to create custom color transformations and effects in DaVinci Resolve, so Davinci Resolve let their user to make custom tool, to create DCTL tools we need to undersanding how to coding especially C programming language and color science for mathematics color." />
                <NoteCourse note="for this module I won't teach you to create DCTL tools because it's to complicated especially if you don't have basic programming, but I will give you my own Custom DCTL for practice or even for your project, this module will related to next module." />
                <br />
                <TextSectionCourse noTitile={false} title="Why we need DCTL ?" text="Maybe you confused Davinci Resolve has a lot of features but why we need DCTL ? so with DCTL we can make all features in Davinci Resolve better and more user friendly because mostly DCTL tools will use slider user interface so it will make you esier to control your color like you using Lightroom application." />
                <br />
                <NoteCourse note="Here my Custom DCTL link :" />
                <div style={{ margin: '0px 4%' }}>
                    <a target='_blank' rel="noreferrer" href={globalVariable.linkDownload} style={{ color: 'white', fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'end', }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
                            <path d="M12 1.5a.75.75 0 01.75.75V7.5h-1.5V2.25A.75.75 0 0112 1.5zM11.25 7.5v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
                        </svg>
                        <p style={{ textDecoration: 'underline' }}>Link Download</p>
                    </a>
                </div>

                <br />
            </div>

        </>
    )
}


