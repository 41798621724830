import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link, useLocation } from "react-router-dom";
import globalVariable from '../data/globalVariable';

// const username = localStorage.getItem('username');
const status = localStorage.getItem('status');

const loggedInNavigation = [
    { name: 'Home', endPoint: '/home', current: true },
    { name: 'Courses', endPoint: '/course', current: false },
    // { name: 'About Me', endPoint: '/about', current: false },
    { name: 'Log Out', endPoint: '/', current: false },
]

const navigation = [
    { name: 'Home', endPoint: '/home', current: true },
    { name: 'Courses', endPoint: '/course', current: false },
    // { name: 'About Me', endPoint: '/about', current: false },
    { name: 'Log In', endPoint: '/login', current: false },
]

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function CustomNavbar() {
    const location = useLocation();


    return (

        <Disclosure as="nav" className="sticky top-0 z-50" id='custNav' style={{ backgroundColor: '#031316' }}>
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-4">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between">
                                <Link to="/home">
                                    <div className="flex flex-shrink-0 items-center">
                                        <img width={40} src={globalVariable.logo} alt="logo" />
                                        <span className="logoNameNav">&nbsp;&nbsp;Effective Colorista</span>
                                    </div>
                                </Link>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4" >
                                        {
                                            status === "true" ? loggedInNavigation.map((item) => (
                                                <Link to={item.endPoint} onClick={() => {
                                                    if (item.name === "Log Out") {
                                                        localStorage.clear();
                                                        window.location.reload();
                                                        window.location.href = '/home';
                                                    }
                                                }} className={classNames(location.pathname === item.endPoint ? 'bg-gray-800 text-white' : 'text-grey-300 hover:bg-grey-900', 'px-3 py-2 rounded-md text-sm font-medium text-white')} aria-current={item.current ? 'page' : undefined}> <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        {item.name} &nbsp; {item.name === "Log Out" ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
                                                        </svg>

                                                            : ""}</div> </Link>
                                            )) :
                                                navigation.map((item) => (
                                                    <Link to={item.endPoint} className={classNames(location.pathname === item.endPoint ? 'bg-gray-800 text-white' : 'text-grey-300 hover:bg-grey-900', 'px-3 py-2 rounded-md text-sm font-medium text-white')} aria-current={item.current ? 'page' : undefined}> <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        {item.name} &nbsp; {item.name === "Log In" ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                            <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                                                        </svg>
                                                            : ""}</div> </Link>
                                                ))

                                        }
                                    </div>
                                </div>
                                {/* <p> Hi! {username}</p> */}
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pt-2 pb-3">
                            {
                                status === "true" ?
                                    loggedInNavigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.endPoint}
                                            onClick={() => {
                                                if (item.name === "Log Out") {
                                                    localStorage.clear();
                                                    window.location.reload();
                                                    window.location.href = '/home';
                                                }
                                            }}
                                            className={classNames(
                                                item.current ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'block px-3 py-2 rounded-md text-base font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    )) :
                                    navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.endPoint}
                                            className={classNames(
                                                item.current ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'block px-3 py-2 rounded-md text-base font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))
                            }
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

