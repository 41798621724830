import CardImage from "../components/CardImage";
import { CustomFooter } from "../components/CustomFooter";
import HeaderSectionPage from "../components/HeaderSectionPage";
import dataPortfolioImage from "../data/galleryImage.json"

export function PortfolioPage() {
    return <>

        <HeaderSectionPage linkButton="" isHasButton={true} buttonName="For colaborration here !" title="Portfolio" image="https://res.cloudinary.com/djhmz6ago/image/upload/v1675787441/colorist/bapak/Untitled_1.128.1_d22reu.jpg" text="This my work from my project I have ever done, pick one project to see the detail of project, if you interest with my work and want to do collaboration you can click here." />
        {/* <BreakPage isHasLink={false} title="Latest Project" subtitle="The project I have done" link="/portfolio" /> */}
        <div className="boxContentPortfolio">
            <div className="boxPortfolioImageGallery">
                {dataPortfolioImage.map((dataImage) =>
                    <CardImage imageId={dataImage.id} image={dataImage.mainImage} title={dataImage.title} subtitle={dataImage.subtitle} />
                )}
            </div>

        </div>
        <CustomFooter />
    </>
}