import globalVariable from "../data/globalVariable";

export function CustomFooter() {
    return (
        <>
            <div className="footerContainer">
                <img src={globalVariable.logo} alt="" width={100} />
                <div className="boxTextFooter">
                    <h1 className="logoNameFooter">Effective Colorista</h1>
                    <p style={{ marginTop: '8px' }}>if you need to use our services you can contact us via the social media listed or send an email to cooperate with us, I hope we can build a good cooperation and we can realize your brilliant idea in color form.</p>
                </div>
                <div className="boxContainerSocialMedia">
                    <p className="titleSosmed" style={{ marginBottom: '8px' }}>Social Media :</p>
                    <div className="boxSocialMedia">
                        <a href={globalVariable.instagramLink} target="_blank" rel="noreferrer">
                            <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={globalVariable.linkedInLink} target="_blank" rel="noreferrer">
                            <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />  <rect x="2" y="9" width="4" height="12" />  <circle cx="4" cy="4" r="2" /></svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="boxCredit">
                <p>Effective Colorista 2022</p>
                <span>Design by: </span> <a target="_blank" rel="noreferrer" href="https://www.instagram.com/aqib_alvaaiziin/"><span style={{ color: "#0082FB" }}>Aqib Alvaaiziin</span >
                </a>
            </div >
        </>
    )
}