import ReactCompareImage from 'react-compare-image';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageOneSeventhModule() {
    const correction = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2FCorrection_1.12.1.jpg?alt=media&token=3245f88b-e092-45fa-9857-3e8bab7c601a";
    const lookDev = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2FLook%20Dev_1.12.2.T.jpg?alt=media&token=ab1bfcd9-e472-4205-b75c-c2f00c44320e";
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title="What is Look Development ?" text="Most Colorist work underneath the Lookup Table (LUT), but that's not LUT in general, colorist or color scientist create specific LUT for spesific task, this aims to make better starting point before do some color grading, this LUT is like a mold when you make some cake, we know when we use mold our cake will look better than we craft by the hand,it's the same concept with color grading, when we have really good LUT our look would be better, the process to create that LUT is called Look Development." />

                <br />
                <ReactCompareImage leftImageLabel='Rec.709 + Correction' leftImage={correction} rightImageLabel='Add Look Development LUT' rightImage={lookDev} sliderLineWidth={2} />
                <br />

                <TextSectionCourse noTitile={true} title="" text="Now I wanna ask to you is look development worth it if you see the image above ? I just add my LUT into it and than boom the look so crisp right, most people hate this process beacuse it takes time to create really good LUT, in this module we will talk about how I create Look Development LUT using DCTL you got in previous module." />
                <br />
            </div>

        </>
    )
}


