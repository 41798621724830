import CustomOutlineButton from "../components/CustomOutlineButton";
import globalVariable from "../data/globalVariable";

export function AboutSection() {

    return (
        <>
            <section className="aboutSection">
                <div className="imageAboutBox">
                    <img src="https://res.cloudinary.com/djhmz6ago/image/upload/v1675964521/colorist/profile/DSCF2432_hdpz4y.jpg" alt="imageabout" style={{ minWidth: '100%', minHeight: '100%', maxHeight: '100%', maxWidth: '100%', objectFit: 'cover' }} />
                </div>
                <div className="aboutBoxText">
                    <div className="headerAbout">
                        <h1>About</h1>
                        <h2>Who I am and what I do</h2>
                        <br />
                        <div className="miniBox"></div>
                        <br /><br />
                        <p>Hai, My name Aqib Alvaaiziin I am Digital Intermediate (Colorist) based in Indonesia, I have a background as a software developer and now I'm exploring the Digital Intermediate field as my main job.</p><br />
                        <p>I do services for color correction and color grading Documentary, TVC, Music Video and Short Film, and I give services to using specific color space you want, I hope we can build a good cooperation and we can realize your brilliant idea in color form.</p>
                        <br /><br />
                        <CustomOutlineButton link={globalVariable.mailTo} title="GET IN TOUCH HERE !" />

                    </div>
                </div>
            </section>
        </>
    )
}
