import ReactCompareImage from 'react-compare-image';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageThreeSecondModule() {
    const incorrect = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2FIncorrect.jpg?alt=media&token=d000918c-b101-4d5f-80fa-bada69d933b8";
    const correct = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%202%2Fcorrected_1.10.5.jpg?alt=media&token=50626dbf-e12b-4322-a707-52152038c0f8";
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title='Are you always need to do color correction ?' text='It depens on material you work, but mostly you always need to do this process, and something you need to concern when you do color correction never mix this process with any color grading step, the purpose of this process to make you esier work for the next step which is color grading, if you do color correction just make sure you can save as much as possible information of your image.' />
                <br />
                <TextSectionCourse noTitile={false} title='When you need to stop color correction process ?' text="You need to stop when you think the information of your image safe and overall image look good and still natural, I'll give you scenario bad correction down below," />
                <br />
                <ReactCompareImage leftImageLabel='Incorrect' leftImage={incorrect} rightImageLabel='Correct' rightImage={correct} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title='' text="as you can see on left image is too contrast, that make the information on black area gone, and that's not good for the next process, that will be hard for you to add some color and make it good, because you do it too much on the first step of your process." />
                <br />
            </div>

        </>
    )
}


