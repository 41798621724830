import { CustomFooter } from "../components/CustomFooter";
import DetailProjectStills from "../components/DetailProjectStills";
import { getQueryVariable } from "../components/GetParams";
import dataGalleries from '../data/galleryImage.json';

export function DetailProjectPage(this: any) {
    const idProject = getQueryVariable('id');
    const mapDataProject = (dataGalleries.filter(datas => datas.id === idProject));
    let dataProject = mapDataProject[0];
    let stills = Array.from(dataProject.stills);

    return (
        <>
            <div className="detailProjectContainer">
                <div className="boxContentProject">
                    <img src={dataProject.mainImage} alt={dataProject.title} style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        borderRadius: 8,
                    }} />
                    <div className="boxDescriptionProject " style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                        borderRadius: 8,
                    }} >
                        <h1 style={{ marginBottom: '4px' }}>{dataProject.title}</h1>

                        <h2>{dataProject.subtitle}</h2>
                    </div>
                    <br />
                </div>
                <DetailProjectStills stills={stills} subtitle={dataProject.subtitle} title={dataProject.title} />

            </div>
            <CustomFooter />
        </>
    )
}

