import { Link } from 'react-router-dom'

interface CardBlogProps {
    image: string,
    title: string,
    desc: string,
    date: string
    linkTo: string
    status: boolean;
}

export default function CardBlog({ image, title, desc, date, linkTo, status }: CardBlogProps) {
    return (
        <div className={status ? 'boxBlogContainer' : 'boxBlogContainerDisabled'}>
            <Link to={linkTo} className={status ? 'nothing' : 'disabled'}>
                <div className="containerBoxImageBlog">

                    <div className={status ? 'blogOverlay' : "blogOverlayDisabled"}></div>
                    <img className='imageBlog' src={image} alt={"image: " + title} loading='lazy' />
                </div>
                <div className={status ? "boxTitleBlog" : "boxTitleBlogDisabled"}>
                    <h1>{title}</h1>
                    <p>{desc}</p>
                    <br />
                    <span>Created at : {date}</span>
                </div>
            </Link>
        </div>
    )
}

