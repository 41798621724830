import ReactCompareImage from 'react-compare-image';
import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ImageContentCourse from '../../../components/course/ImageContentCourse';
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';

export function PageTwoFifthModule() {
    const sharedNodeScenario = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2FScenario%20Scene.png?alt=media&token=3cc12af1-d022-4a5d-ae07-d994e4df5155";
    const sharedNodeNodtree = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2FNode.png?alt=media&token=900766da-c748-4bc6-bef8-1df5a418ca8d";
    const sharedNodeBefore = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2Fshare%20node%20before.png?alt=media&token=a8ba8698-0b15-4a4a-ae23-b631162331c3";
    const sharedNodeAfter = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2Fshare%20node%20after.png?alt=media&token=859910ea-62bb-4319-8a07-f97adb8f39a4";

    const groupScenario = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2FScenario%20Group.png?alt=media&token=80b445a3-93a2-4654-958a-4b618d3c63c2";
    const groupChain = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2FGraoup%20Link.png?alt=media&token=83118cce-aed9-4da9-b8fc-8d8a5e287fa9";
    const groupEffect = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%205%2FGroup%20Effect.png?alt=media&token=5a68bd56-01b0-4e98-9968-7edbb934bb05";

    const grouping = [
        {
            "title": "Group Pre-Clip",
            "value": "Most people use Group Pre-Clip to add adjustment even before the clip level, mostly I used for highlight recovery, noise reduction, and Input Data Transform(CST Input) when I use node based color management.",
            "image": ""
        },
        {
            "title": "Group Post-Clip",
            "value": "I use Group Post-Clip to create look, create look on group level will save your time because you don't need to copy the look one by one for clip in a same scene, and this method will make your look more consistant",
            "image": ""
        }
    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title="Shared Node" text="As you know in previous page this concept will give you benefit to create one node and all adjustment will be affected to your clip with this node, I'll give you scenario like image down below, I have a clip with same places so I can create same look to that clip, I will demonstrade using shared node for look of this scene, and you can see the node image in right image, to crate shared node you can right click to node you want and choose 'Save as Shared Node' the arrow icon will be appear above the node it means that node is Shared Node right now, I applied the same node to all clip so if I do adjustment on shared node all clip will get that adjustment." />
                <br />
                <NoteCourse note="(After module 4 I will use color management scenario in every example so don't be confused if you not see any CST on my node)" />
                <br />
                <ReactCompareImage leftImageLabel='Scenario' leftImage={sharedNodeScenario} rightImageLabel='Node Tree' rightImage={sharedNodeNodtree} sliderLineWidth={2} />
                <br />
                <ReactCompareImage leftImageLabel='Correction' leftImage={sharedNodeBefore} rightImageLabel='Look From Shared Node' rightImage={sharedNodeAfter} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title="" text="As you can see I just create look on one node in one clip but all the the image changed becasue the shared node, this tools look really good right but if you work on long timeline it will be confusing if you not really remember what you did, so let's take a look to another method in my opinion better for long timeline." />
                <br />
                <TextSectionCourse noTitile={false} title="Grouping" text="Actually this concept is the same with shared node but you have more room to explore your workflow, to use this technique first of all we need to know what kind of scene we want to group, me personally create group by scene, look at image down bellow, I will create 2 group one for indoor and one for outdoor, to create a group you can simply pick any clip you want to group and right click on thubnail clip -> choose 'Add into a New Group'-> type name of group and then click 'OK'." />
                <br />
                <ReactCompareImage leftImageLabel='Scene Scenario' leftImage={groupScenario} rightImageLabel='After Group Created' rightImage={groupChain} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title="" text="After you create group something happen with node work space you will see on top right corner now you have Group Pre-Clip, Clip, Group Post-Clip, and timeline, Group Pre-Clip and Group Post-Clip only appear when you work on group level node, but what is different with the basic Clip node ?" />
                <ImageContentCourse image={groupEffect} title='colorspace' />
                <TextSectionCourse noTitile={true} title="" text="As the name Group Pre-Clip & Group Post-Clip when we add adjustment on that two any clip in that group will be affected, this concept has a same effect with shared node, but in this method you can do any adjustment more organize, so what the difference from Pre & Post Clip ?" />

                <ListDataCourseTitle listData={grouping} />
                <div style={{ marginTop: '-16px' }}>
                    <TextSectionCourse noTitile={true} title="" text="Now with this method our workflow more organized and the look we create will more consistant, in Clip level you just need do some color correction now because all the look is cover on Group post clip." />
                </div>
                <br />

                <NoteCourse note="Pro Tip : When you use grouping method I do some color correction only on one clip in a scene and then I create look on group post clip, if the process done, I just need do color correction and shot match for entire clip in a scene, it will safe a lot of time." />

                <br />
            </div>

        </>
    )
}


