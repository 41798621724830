import ReactCompareImage from 'react-compare-image';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';

export function PageOneThirdModule() {
    const real = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FThe_Joker_033.jpg?alt=media&token=48c170e1-5958-4efa-b56e-beee55794541";
    const fake = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FJoker%20change_1.14.2.jpg?alt=media&token=1b7ddbde-c26e-44eb-807d-61c79603dece";
    const good = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FRight%20Reference.jpg?alt=media&token=06bd8b33-1835-4b0b-b5e7-8c94b45aff8f";
    const bad = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2Fwrong%20reference.jpg?alt=media&token=e0a7da9d-4091-44e5-a125-4e5d68da9237";

    const listBeforeGrade = [
        {
            "title": "Decide Mood",
            "value": "Before you color grade your video after do color correction, you need to decide what kind of mood or feeling you want to tell your story, you want to make it natural or emotional it depends on the story you want to tell,",
            "image": ""
        },
        {
            "title": "Find Fit Reference",
            "value": "Find a good reference to give you visualization about your feeling, find the reference with same ambient, just make it normal, to find reference you can find using Shotdeck, Film-Grab, Youtube, ECT, look at the image below for example how to pick a good references,",
            "image": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FCompare%20Reference.png?alt=media&token=3611a700-f428-41f0-aa57-e587f69cdb8f"
        }
    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={true} title='' text="In module 2 we learn about Color Correction and now we will talk about color grading, and what's the different between the two." />
                <br />
                <TextSectionCourse noTitile={false} title='Color Grading' text="In general Color Grading is a process to tell the story and feeling into your video with color, what is that mean ? color have an emotion as you know like the red color depicts anger, anthusiasm, fiery, blue color depicts sad, lonely, scare ETC. look at the image down below." />
                <br />
                <ReactCompareImage leftImageLabel='What If ?' leftImage={fake} rightImageLabel='Real Movie' rightImage={real} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title='' text="if you watching The Joker movie in this scene Arthur (Joker) he felt disappointed and angry with Murray Franklin (TV host) because he make a fun about arthur in his show, as you can see in the image right above, Colorist make the background look deep blue green, in this case that color is a story to describe how anger and disappointment him(Arthur) with Murray, what if the colorist make it like the left image? what something in our mind will be thinking? for me I will make argument the image look so flat and I don't feel any emotion here, as you can see from this example color grading is not only about make image looks good, but you need to understand what the message you want to reperesent into your audience." />
                <br />
                {/* add image from film grab to tell about color story */}
                <TextSectionCourse noTitile={false} title='What you need before color grading ?' text="Before you do color grading you need to do this step to make your color grading consistant and on point as your plan." />
                <br />
                <ListDataCourseTitle listData={listBeforeGrade} />

                <TextSectionCourse noTitile={true} title='' text="As you can see here we have two reference left image from the troll and right image from Blade Runner 2049, when you look the environment of left image that's more fit with this scenario because the place is same on the mountain even both image is look crazy cinematic, I will make scenario why the left image is better reference for this material, she was on his way to find peace and that not easy, so the dominant blue color will represent lost & lonely that's why the left reference better for this material," />
                <br />
                <ReactCompareImage leftImageLabel='Good Reference' leftImage={good} rightImageLabel='Bad References' rightImage={bad} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title='' text="You can see how big different if you can find really good reference for your material, it will be a surprised you than you just try to find by your own, so this step is really-really important to do, it will make you esier when do color grading because you know what kind visualization you want to achieve." />
                <br />
            </div>

        </>
    )
}


