import ReactCompareImage from 'react-compare-image';
import ListDataCourse from '../../../components/course/ListDataCourse';
import ListDataCourseImageCompare from '../../../components/course/ListDataCourseImageCompare';
import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageTwoSixthModule() {

    const node = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Fnode%20tree.png?alt=media&token=2316b887-402a-434b-a7e4-3bce7ea5b04d";
    const rec = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Frec_1.2.5.jpg?alt=media&token=3d6f54b8-9fdc-45b2-bcae-439cf6aabeba";

    const installDctl = [
        "Download DCTL from Google Drive in previous page",
        "Open Davinci Resolve",
        "Open Project Setting",
        "Click Color Management tab",
        "Go down to Lookup Tables section and then click Open Lut Folder",
        "Create new folder with name 'DCTL'",
        "Copy all DCTL file you downloaded and paste in new DCTL folder",
        "Reload Davinci Resolve",
        "Go to color page, drag the DCTL OFX",
        "Now you can access all my DCTL"
    ];

    const dctlList = [
        {
            "title": "Linear Correction",
            "value": "In this tools I divided it into 4 parts exposure, balance, saturation and density, for exposure you can use Linear Exposure, Linear Contrast and Pivot Contrast this exposure setting will give you linear adjustment so it will be more safe for your image, for balance you can use Red-Cyan, Green-Magenta, and Blue-Yellow this tools work like hdr global wheel with slider UI it will make you more esier to do balancing, for saturation you have 4 option global Saturation, and 3 other will add saturation depends on the color on image, for example Hue Cyan will control saturation blue and green color because cyan is a mixture of green and blue, and last thing, density will work for change density in color RGB Channel.",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Fcorrection.png?alt=media&token=9fe28afe-6d5c-454e-b20a-08bbfe6e16b3",
            "leftImageName": "Linear Correction DCTL",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Fcorrection_1.2.4.jpg?alt=media&token=b2130a73-aecf-434e-960e-a8bdacf903b6",
            "rightImageName": "Result",
        },
        {
            "title": "Split Tone",
            "value": "I always use this tool to add some color shadow and highlight area, the special thing of this tools it work on specific middlegray color space, this tools work like custom curve on Davinci Resolve, the reason I create this because when I use curve on Resolve I can crate conssistent value for dot point, so this tools is answer for that problem.",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Fsplit%20tone.png?alt=media&token=b31a404a-18ab-4d22-a586-47cb00a23c4f",
            "leftImageName": "Split Tone DCTL",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Fsplittone_1.2.3.jpg?alt=media&token=1060b0e8-e68b-4965-b24f-36d2d4456c9e",
            "rightImageName": "Result",
        },
        {
            "title": "Tetra RGB Shift",
            "value": "This DCTL is free on internet, this is tetra DCTL this is the most powerfull free DCTL, it work to add RGB color on specific hue, as you can see after I add split tone the color of water looks more cyan blue right, and than I reduce the cyan red slider to make water look more blue, this tools will help a lot to push more color you want for your look.",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Frgb.png?alt=media&token=fe34407d-317d-4012-bc18-46b03e2d3da4",
            "leftImageName": "Tetra RGB Shift DCTL",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Frgb%20shift_1.2.2.jpg?alt=media&token=a389d042-ae04-4221-bd79-85ceb105b433",
            "rightImageName": "Result",
        },
        {
            "title": "Tetra HSV Shift",
            "value": "Like the Tetra RGB Shift this tools is totally free, this tools work look like hue vs in Davinci Resolve, but this tools work with more power, I always use it after Tetra RGB especially to control saturation and values.",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2FHSV.png?alt=media&token=450fdd9d-0ec8-48b6-b3f0-2810b94b7e6c",
            "leftImageName": "Tetra HSV Shift DCTL",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%206%2Fhsv%20shift_1.2.1.jpg?alt=media&token=a7d4882a-8e53-4e0b-976b-1832e141707b",
            "rightImageName": "Result",
        }
    ]
    return (
        <>

            <div>
                <br />
                <NoteCourse note='To use DCTL you need Davinci Resolve Studio because DCTL OFX only can access in studio version.' />
                <TextSectionCourse noTitile={false} title="How to install ?" text="To install the DCTL is pretty easy, it that just same concept with install LUT in Davinci Resolve, so just follow the step below and you can use the DCTL : " />
                <ListDataCourse listData={installDctl} />
                <br />
                <TextSectionCourse noTitile={false} title="DCTL You Get" text="From Google Drive you will get 5 DCTL file now I will explain each fucntion from DCTL you get, remember my DCTL work in color management workflow, you can use node base or project base, this DCTL will work well in 3 Color Space ArriLogC3, ACEScct, and Davinci Wide Gamut, for now I will give you scenario I use color management in ArriLogC3 and I will do some color grading using all DCTL you get." />

                <br />
                <ReactCompareImage leftImageLabel='Node Scenario' leftImage={node} rightImageLabel='Footage' rightImage={rec} sliderLineWidth={2} />
                <br />
                <ListDataCourseImageCompare listData={dctlList} />
                <br />
                <TextSectionCourse noTitile={true} title="How to install ?" text="as you can see how powerfull this tools, even you can do grading a whole project only with this tools, if you understand how to use it properly you can create unique look faster with this tools, the purpose I create this tools is about speed and and accuracy, with slider interface it will make everything look easier." />
                <br />
            </div>

        </>
    )
}


