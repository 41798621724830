import ReactCompareImage from 'react-compare-image';
import './courseStyle.css'

interface ListDataCourseTitleProps {
    listData: { "title": string, "value": string, "leftImage": string, "leftImageName": string, "rightImage": string, "rightImageName": string }[];
}

export default function ListDataCourseImageCompare({ listData }: ListDataCourseTitleProps) {
    return (
        <div className="containerContentCourse">
            <ul>
                {listData.map((data) =>
                    <li>
                        <h3 style={{ fontWeight: 'bold' }}>{data.title}</h3>
                        <p className='textListSize'>{data.value}</p>
                        <br />
                        <ReactCompareImage leftImageLabel={data.leftImageName} leftImage={data.leftImage} rightImageLabel={data.rightImageName} rightImage={data.rightImage} sliderLineWidth={2} />
                        <br />
                    </li>
                )}
            </ul>
        </div>
    )
}

