import CustomOutlineButton from "../components/CustomOutlineButton";
import globalVariable from "../data/globalVariable";

export function HeaderHome() {

    return (
        <section className="headerHome">
            <img className="backgoroundHomeHeader" src="https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Extras%2Fme_2.3.1.JPG?alt=media&token=813ae54c-db0d-45ab-be87-7119f5d4f07d" alt="headerPage" />
            <div className="contentHomeHeaderBox">
                <div className="logoNameBox">
                    <img src={globalVariable.logo} alt="headerPage" width={50} style={{ objectFit: 'cover' }} />
                    <div className="myNameAndRole">
                        <p className="roleTitle">Aqib Alvaaiziin</p>
                        <p className="roleSubtitle">Digital Intermediate (Colorist)</p>
                    </div>
                </div>
                <div className="boxTitleHeader">
                    <h1>Make your film more cinematic with our color services</h1>
                    <h2>Get the best look for your film with us here.</h2>
                </div>
                <CustomOutlineButton link={globalVariable.mailTo} title="GET IN TOUCH HERE !" />
                <div className="socialMediaHeaderBox">
                    <a href={globalVariable.instagramLink} target="_blank" rel="noreferrer">
                        <svg className="h-8 w-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={globalVariable.linkedInLink} target="_blank" rel="noreferrer">
                        <svg className="h-8 w-8 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />  <rect x="2" y="9" width="4" height="12" />  <circle cx="4" cy="4" r="2" /></svg>
                    </a>
                </div>
            </div>
        </section>
    )
}
