import ImageContentCourse from '../../../components/course/ImageContentCourse';
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageOneFourthModule() {
    const colorspace = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%204%2Fcolorspace.png?alt=media&token=bfeb8eb4-c117-417a-86f0-c4aea2be2e03";
    const typeColorspace = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%204%2Ftype%20colorspace.png?alt=media&token=11f4a932-a212-40f2-9ca6-8a0d58ec5344";
    const colormanagement = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%204%2FColor%20Mangemenet%20analogy.png?alt=media&token=6d339126-00da-465c-b83b-9c1faf845ab0";
    const listTypeColorSpace = [
        {
            "title": "Camera Color Space",
            "value": "Maybe you already know camera color space like ArriLogC3, Sony S-Gamut, RedLog3G10, Panasonic V-Gamut, Ect. this color space is create to get as much as possible information can capture by camera, and every single camera brand has different Color Spaces.",
            "image": ""
        },
        {
            "title": "Display Color Space",
            "value": "Rec. 709 is most common display color space, maybe you always use it when do some convertion from your camera to display colorspace, display color space is designed for broadcast purpose, it can be REC. 709 for media social broadcast like a website, REC. 2020 for high quality image like 4K and Ultra HD technologies, and DCI-P3 for cinema purposes",
            "image": ""
        },
        {
            "title": "Scene Color Space",
            "value": "And The last is Scene Color Space, Davinci Resolve has Aces and Davinci Wide Gamut Color Space this color spaces are not built around limitation of a camera or display technology, and we will talk more about this in this module.",
            "image": ""
        }
    ]

    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={true} title="" text="Before we learn about 'Color Management' I will explain what you need to know before you use it in your next project." />
                <br />
                <TextSectionCourse noTitile={false} title="What is Color Space ?" text="I will give you demonstration what is color space, we know camera can capture the image and monitor / TV can display the image they are both communicate to reproduce the color and light so we know what our eyes can see, to make this analogi more esier lets asume the camera talk with english language and the monitor talk with english too so they can communicate, the language in here it means Color Space, so Color Space is a places for camera and display to communicate each other, they communicate to match the Color and Brighness." />
                <ImageContentCourse image={colorspace} title='colorspace' />
                <br />
                <TextSectionCourse noTitile={false} title="Type of Color Space ?" text="Different Color Spaces are design for different thing we will breakdown one by one. " />
                <ListDataCourseTitle listData={listTypeColorSpace} />
                <ImageContentCourse image={typeColorspace} title='colorspace' />
                <br />
                <TextSectionCourse noTitile={false} title="What is Color Management ?" text="Color management is a workflow, in some project maybe you will have footage from different camera, let's asume you work with Sony and Panasonic Camera and will deliver to Rec. 709, as we talk previously the every camera brand has different color spaces, so it will be hard to match the color between the two camera, and the color management is answer of this problem, when we work with more than one camera color management will help to reconciling all of those color space into an appropriate color space for our delivery." />

                <ImageContentCourse image={colormanagement} title='colorspace' />

                <br />
            </div>

        </>
    )
}


