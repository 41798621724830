import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'
import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';
import ReactCompareImage from 'react-compare-image';

export function PageTwoThirdModule() {

    const log = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FLog.jpg?alt=media&token=368295a6-87e9-4992-bb52-e29b1994ffe7";
    const split = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FSplit%20Tone.jpg?alt=media&token=69e6fdb4-f7d1-49bd-910a-4b1d3c1e16ab";
    const pallete = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%203%2FPallete.jpg?alt=media&token=0e8dadeb-f69b-4596-9924-dd4cfa054cd0";

    const splitToneText = [
        {
            "title": "Split Tone",
            "value": "Split Tone is process of changing the colors of an image based on luminance value, so what is that means?, it means you can change color on specific area like shadow, midtone and highlight, this step will help you to add the color you want to represent, how to use it ? there's many way to do split tone, I will give you example using lift gamma gain wheel, now I will create most common use color teal & orange and just make it clean for commercial video, basically I will add some cold color like blue, green or cyan into shadow area, and then add warm color like orange in highlight area.",
            "image": ""
        },
    ]

    const PalleteText = [
        {
            "title": "Palette",
            "value": "Color pallete is process to create harmony based on the environment of your footage, what is that mean ? so we will adjust some specific color to look better after we adding the split tone, in this case I will make the red color on environment look darker, it will help the talent look more pop & 3D, in this case the fashion product is hat so I add mor saturated color to create higlight on the product, I use HSL Curve to get that look.",
            "image": ""
        },
    ]

    return (
        <>

            <div>
                <br />

                <TextSectionCourse noTitile={true} title='' text="Now we in the most fun step of this module, we will learn about how to color grade, and which tools you need to use to get the look you want, there's two thing you need to focus on, that's Split Toning & Color Palette." />

                <br />
                <ListDataCourseTitle listData={splitToneText} />
                <ReactCompareImage leftImageLabel='Log' leftImage={log} rightImageLabel='Correction + Split Tone' rightImage={split} sliderLineWidth={2} />
                <br />
                <ListDataCourseTitle listData={PalleteText} />
                <ReactCompareImage leftImageLabel='Correction + Split Tone' leftImage={split} rightImageLabel='Palette Added' rightImage={pallete} sliderLineWidth={2} />
                <br />
                <TextSectionCourse noTitile={true} title='' text="Now maybe you're thinking about why the definition and the technique I share in here is different, in definition we talk about how to tell the story from color, but now we do another case, if you thinking like that you're totally wrong, let's breakdown that, in this case we create fashion commercial clean look, so what kind of story on this color grading, look at the image again, as you can see we have two strong color right in wall behind and the product (hat), we all know the product is hat right not that wall event both of them have strong color too, so in here we success to create the product look more pop and seperate the background color, so what the feelings ? with the model expretion, she feel really confident use that product it will emphasize our mind she looks cool using that product, and remember red color is represent confident too, so this shot is really tell the message like I want to my audience." />
                <br />
            </div>

        </>
    )
}


