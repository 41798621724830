import React from 'react'
import { Link } from 'react-router-dom'

interface BreakPageProps {
    title: string,
    subtitle: string,
    link: string
    isHasLink: boolean
}

export default function BreakPage({ title, subtitle, link, isHasLink }: BreakPageProps) {
    return (
        <>
            <div className="backgroundPage">
                <div className="titleName">{title}</div>
                <div className="subtitleName"><span>{subtitle}</span>
                    {isHasLink ?
                        <span className='link'> /<Link to={link}> View All</Link></span> : <span></span>
                    }
                    <br /><br />
                    <div className="miniBox" style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}></div>
                </div>
            </div>
        </>
    )
}

