import { AboutSection } from "../page component/AboutSection";
// import BreakPage from "../components/BreakPage";
// import ImageGallery from "../components/ImageGallery";
import { CustomFooter } from "../components/CustomFooter";
import { HeaderHome } from "../page component/HeaderHome";

export function HomePage() {

    return (
        <div style={{maxWidth: '100%'}}>
            <HeaderHome />
            <AboutSection />
            {/* <BreakPage isHasLink={true} title="SOME STILL OF MY WORK" subtitle="My latest project" link="/portfolio" /> */}
            {/* <ImageGallery /> */}
            <CustomFooter />
        </div >
    )
}
