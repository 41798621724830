import ListDataCourseTitle from '../../../components/course/ListDataCourseTitle';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import '../course.css'

export function PageThreeFourthModule() {
    const comparison = [
        {
            "title": "Flexibility",
            "value": "For flexibility the Node Base Color Management is better, undoubtedly this method is the most flexible and powefull tool set for color management.",
            "image": ""
        },
        {
            "title": "Time",
            "value": "Project Setting Color Management will bang the Node Base Method, with one setting you can go for grading, if you consider time you work for your project, prject setting will be good for you.",
            "image": ""
        },
        {
            "title": "Simplicity",
            "value": "Another point for Project Setting Color Management, when you use Node Base Method sometimes it will be confusing because so much node you have on your workflow, with Node Base Method when you use some qualifier the best keying you can do after the output transform, it means your adjustment is out from the pipeline, or maybe you using 2 RGB input to keying, it just make your node more messy",
            "image": ""
        }
    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={false} title="Node Base VS Project Setting" text="After you know how to set the color management with two method now something on your mind maybe will asking, what's the different between that two ? when I need to use it ?, which one better, and ETC. I'll give you scenario to make you understand." />
                <br />
                <ListDataCourseTitle listData={comparison} />
                <TextSectionCourse noTitile={false} title="Is Color Management Optional ?" text="It depends on how you want to work with your project, for myself I prefer use color management, especially use project setting method, because that make my workflow eisier." />

                <br />
            </div>

        </>
    )
}


