import './courseStyle.css'

interface ImageContentCourseProps {
    image: string,
    title: string
}

export default function ImageContentCourse({ image, title }: ImageContentCourseProps) {


    return (
        <div className="ImageCourseContentBox">
            {

                <img className='imageContentCourse' src={image} alt={title} />

            }
        </div>
    )
}

