import { Routes, Route, Navigate } from "react-router-dom";
import { AboutPage } from "../pages/AboutPage";
import { DetailProjectPage } from "../pages/DetailProjectPage";
import { HomePage } from "../pages/HomePage";
import MainPage from "../pages/MainPage";
import { PortfolioPage } from "../pages/PortfolioPage";
import { LoginPage } from "../pages/LoginPage";
import { FirstModule } from "../pages/courses/module 1/FirstModule";
import { SecondModule } from "../pages/courses/module 2/SecondModule";
import { ThirdModule } from "../pages/courses/module 3/ThirdModule";
import { FourthModule } from "../pages/courses/module 4/FourthModule";
import { FifthModule } from "../pages/courses/module 5/FifthModule";
import { SixthModule } from "../pages/courses/module 6/SixthModule";
import { SeventhModule } from "../pages/courses/module 7/SeventhModule";
import { CoursesPage } from "../pages/CoursesPage";


const MainRoutes = () => (
    <Routes>
        <Route path="/" element={<MainPage />}>
            <Route path="/" element={<Navigate replace to="/home" />}> Home </Route>
            <Route path="home" element={<HomePage />}> Home </Route>
            <Route path="about" element={<AboutPage />}> About Me </Route>
            <Route path="portfolio" element={<PortfolioPage />}> Portfolio </Route>
            <Route path="detailProject" element={<DetailProjectPage />}> Detail Project </Route>
            <Route path="course" element={<CoursesPage />}> Courses </Route>
            <Route path="course/xkilmhj1p1" element={<FirstModule />}> Module 1 </Route>
            <Route path="course/xkilmhj2p2" element={<SecondModule />}> Module 2 </Route>
            <Route path="course/xkilmhj3p3" element={<ThirdModule />}> Module 3 </Route>
            <Route path="course/xkilmhj4p4" element={<FourthModule />}> Module 4 </Route>
            <Route path="course/xkilmhj5p5" element={<FifthModule />}> Module 5 </Route>
            <Route path="course/xkilmhj6p6" element={<SixthModule />}> Module 6 </Route>
            <Route path="course/xkilmhj7p7" element={<SeventhModule />}> Module 7 </Route>
            <Route path="login" element={<LoginPage />}> Login </Route>
        </Route>
    </Routes>

)

export default MainRoutes; 