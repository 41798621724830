import ImageContentCourse from '../../../components/course/ImageContentCourse';
import ListDataCourse from '../../../components/course/ListDataCourse';
import ListDataCourseImageCompare from '../../../components/course/ListDataCourseImageCompare';
import NoteCourse from '../../../components/course/NoteCourse';
import TextSectionCourse from '../../../components/course/TextSectionCourse';
import globalVariable from '../../../data/globalVariable';
import '../course.css'

export function PageTwoSeventhModule() {
    const node = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2FLook%20Development%20(1).png?alt=media&token=bb2fe7d1-9f3d-4d67-b3a0-38ceb5c7df00";


    const listNode = [
        "First node for DCTL RGB Shift to create palette",
        "Second node for DCTL HSV Shift to control the palette",
        "Third node for DCTL Split Tone to add tone color",
        "Fourth node for custom curve to create contrast",
        "Fifth node for balance shadow and highlight",
    ];

    const lookDevList = [
        {
            "title": "Chart Footage",
            "value": "",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.10.2.T.jpg?alt=media&token=189e35f2-b1ac-4478-849d-8960a081ff9b",
            "leftImageName": "Rec. 709",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.10.1.jpg?alt=media&token=c51cc45b-aef3-426e-a8f6-4319ae3753a6",
            "rightImageName": "Correction + Add Look Development",
        },
        {
            "title": "Sunrise",
            "value": "",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.11.1.jpg?alt=media&token=aa6cf014-6513-4f32-9b19-79f99fe64740",
            "leftImageName": "Rec. 709",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.11.2.jpg?alt=media&token=02182981-4e4d-426b-bc2f-74ca10c7e707",
            "rightImageName": "Correction + Add Look Development",
        },
        {
            "title": "Daylight",
            "value": "",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.7.1.jpg?alt=media&token=313e765f-7736-474c-8816-9df122980708",
            "leftImageName": "Rec. 709",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.7.2.jpg?alt=media&token=b452b793-bb11-4362-821c-7126b94ef493",
            "rightImageName": "Correction + Add Look Development",
        },
        {
            "title": "Low Light",
            "value": "",
            "leftImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.14.2.jpg?alt=media&token=5bc6fdbc-c226-4010-9b62-6f18052d66fc",
            "leftImageName": "Rec. 709",
            "rightImage": "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Module%207%2Fdev_1.14.1.T.jpg?alt=media&token=970b6557-b864-4b86-9d91-883e2797e153",
            "rightImageName": "Correction + Add Look Development",
        },

    ]
    return (
        <>

            <div>
                <br />
                <TextSectionCourse noTitile={true} title="" text="In Look Development you need to concern to 3 point, Contrast, Tone, and Pallete, and to check your result when you do this process you need use footage with color chart, you can download the chart footage down below for your exercise :" />
                <br />
                <div style={{ margin: '0px  4%' }}>
                    <a target='_blank' rel="noreferrer" href={globalVariable.linkDownload} style={{ color: 'white', fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'end', }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
                            <path d="M12 1.5a.75.75 0 01.75.75V7.5h-1.5V2.25A.75.75 0 0112 1.5zM11.25 7.5v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
                        </svg>
                        <p style={{ textDecoration: 'underline' }}>Link Download</p>
                    </a>
                </div>
                <br />
                <NoteCourse note="(The file name is color space for each footage)" />
                <TextSectionCourse noTitile={true} title="" text="Now I'll give you scenario to do Look Development using the DCTL you got before, now in timeline you can add footage with different lighting condition for sample of your look development, but we will focus on chart footage you download before because we need to know which color will be affected and control the changes." />
                <ImageContentCourse image={node} title='node' />
                <ListDataCourse listData={listNode} />
                <br />
                <TextSectionCourse noTitile={true} title="" text="Before you play around in timeline level you need to do color correction to your footage first, you can use Color Match Features so it should be acurate for color correction, now you can play around with your tools in timeline node to get desire look you want." />
                <br />
                <ListDataCourseImageCompare listData={lookDevList} />
                <TextSectionCourse noTitile={true} title="" text="as you can see only with that node we can create really good look for different lighting condition, in color correction process I just change exposure and balancing for all footage, if you work on project and you already has LUT like this it will give you more vision to explore the look." />
                <br />
            </div>

        </>
    )
}


