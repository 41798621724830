import ImageContentCourse from './ImageContentCourse';
import './courseStyle.css'

interface ListDataCourseTitleProps {
    listData: { "title": string, "value": string, "image": string }[];
}

export default function ListDataCourseTitle({ listData }: ListDataCourseTitleProps) {
    return (
        <div className="containerContentCourse">
            <ul>
                {listData.map((data) =>
                    <li><h3 style={{ fontWeight: 'bold' }}>{data.title}</h3><p className='textListSize'>{data.value}</p> {data.image === "" ? <br></br> : <ImageContentCourse image={data.image} title={data.title} />} </li>
                )}
            </ul>
        </div>
    )
}

