import { Link } from "react-router-dom";

interface VerticalImageProps {
    title: string,
    image: string,
    imageId: string,
    subtitle: string
}

export default function VerticalImage({ title, image, imageId, subtitle }: VerticalImageProps) {


    return (
        <Link to={`/detailProject?id=${imageId}`}>

            <div className="boxVerticalImage" >
                <img className="contentVerticalImage" src={image} alt={title} style={{
                    objectFit: 'cover', width: '100%', height: '100%'
                    , overflow: 'hidden'
                }} />
                <div className="filmName">
                    <h1 className="filmTitle">{title}</h1>
                    <p className="filmSubitle">{subtitle}</p>
                </div>
            </div>
        </Link>
    )
}
