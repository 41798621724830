import React, { useState, MouseEvent } from "react";
import { CustomFooter } from "../components/CustomFooter";
import allUsers from "../data/userData.json";


export function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userNotFound, setUserNotFound] = useState(false);
    const photo = "https://firebasestorage.googleapis.com/v0/b/effective-colorista.appspot.com/o/Extras%2FLogin%20image.jpg?alt=media&token=7f7398cc-d815-4497-86d9-653c31b83696";

    const usernameHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
        setUserNotFound(false);
    };

    const passwordHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setUserNotFound(false);
    };

    const setDataUserStorage = (userId: string, username: string, role: string) => {
        localStorage.setItem('userId', userId);
        localStorage.setItem('username', username);
        localStorage.setItem('status', "true");
        localStorage.setItem('role', role);
    }

    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const user = allUsers.filter((users) => users.username === username && users.password === password);
        if (user.length > 0) {
            setDataUserStorage(user[0].userId, user[0].username, user[0].role);
            window.location.reload();
            window.location.href = '/home';
        } else {
            setUserNotFound(true)
        }
    };
    const handleEnterEvent = (event: any) => {
        if (event.key === 'Enter') {
            const user = allUsers.filter((users) => users.username === username && users.password === password);
            if (user.length > 0) {
                setDataUserStorage(user[0].userId, user[0].username, user[0].role);
                window.location.reload();
                window.location.href = '/home';
            } else {
                setUserNotFound(true)
            }
        }
    };


    return (<>
        <div className="loginSection">
            <div className="loginBox">
                <p className="loginHeader">Log In To Our Page For More Access</p>
                <div>
                    <div className="formBox" onKeyDown={handleEnterEvent}>
                        <label htmlFor="username">Username</label>
                        <input type="text" name="username" value={username} onChange={usernameHandle} placeholder="Username" />
                        <p style={{ margin: '10px 0px' }}></p>
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password" value={password} onChange={passwordHandle} placeholder="Password" />
                    </div>
                    {userNotFound ? <p className="userNotFound"> User Not Found </p> : <span></span>}
                    <button id="loginbutton" onClick={handleMouseEvent}>Log In</button>
                </div>
                <div>
                    <p className="loginNoteHeader">Owner Note :</p>
                    <span className="loginNote">You can only access this page with an account that has been authorized by the owner, reach the owner with this link down below:</span>
                    <br /><br />
                    <a target="_blank" rel="noreferrer" style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '16px' }} href="https://ig.me/m/aqib_alvaaiziin">Owner here.</a>
                </div>
            </div>
            <img className="imageLoginBox" src={photo} alt="" />

        </div>

        <CustomFooter />
    </>)

}